<template>
  <footer id="footer">
    <div class="container">
      <div class="footer-inner wow pixFadeUp">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="widget footer-widget">
              <h3 class="widget-title" data-i18n="our_address"></h3>
              <p>
                Pleyades 126, Malecon, Ocotlan Jalisco 47860
                contacto@mikmic.com
                Tel: 3317382691
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="widget footer-widget">
              <ul class="footer-social-link" style="float: right">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100091483731250" target="_blank">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/mikmic_1/" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="site-info">
        <div class="copyright">
          <p>
            <span data-i18n="all_rights_reserved"></span>
            <a href="https://system.mikmic.com/" target="_blank">MikMic</a>
          </p>
        </div>
        <ul class="site-info-menu">
          <li>
            <a data-i18n="privacy_policy"></a>
          </li>
          <li>
            <a data-i18n="faq"></a>
          </li>
          <li>
            <a data-i18n="terms"></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TemplateFooterPage",
  props: {
    msg: String,
  },
};
</script>
