<template>
  <div>
    <TemplateHeaderPage></TemplateHeaderPage>
    <router-view></router-view>
    <TemplateFooterPage></TemplateFooterPage>
  </div>
</template>

<script>
import TemplateHeaderPage from "@/components/TemplateHeaderPage.vue";
import TemplateFooterPage from "@/components/TemplateFooterPage.vue";
export default {
  name: "MainLayout",
  components: {
    TemplateHeaderPage,
    TemplateFooterPage,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
