<template>
  <header class="site-header pix-header-fixed" data-top="992">
    <div class="container">
      <div class="header-inner">
        <div class="site-mobile-logo">
          <a href="/" class="logo">
            <img src="@/assets/mikmic/mikmic-1.png" alt="site logo" class="main-logo" />
          </a>
        </div>
        <div class="toggle-menu" @click="toggleMenu">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
        <nav class="site-nav">
          <div class="close-menu" @click="closeMenu">
            <span>{{ $t('close') }}</span>
            <i class="ei ei-icon_close"></i>
          </div>
          <div class="site-logo">
            <a href="index.html" class="logo">
              <img src="@/assets/mikmic/mikmic-1.png" alt="site logo" class="main-logo" />
              <img src="@/assets/mikmic/mikmic-1.png" alt="site logo" class="sticky-logo" />
            </a>
          </div>
          <div class="menu-wrapper" data-top="992">
            <ul class="site-main-menu">
              <li @click="closeMenu">
                <router-link :to="{ path: '/'}">{{$t('home')}}</router-link>
              </li>
              <li @click="closeMenu">
                <router-link :to="{ path: '/about'}">{{$t('about')}}</router-link>
              </li>
              <li @click="closeMenu">
                <router-link :to="{ path: '/drivers'}">{{$t('frequentQuestions')}}</router-link>
              </li>
              <li @click="closeMenu">
                <router-link :to="{ path: '/advertising'}">{{$t('advertising')}}</router-link>
              </li>
              <!-- <select id="language-picker" class="selectpicker" data-width="fit" v-model="locale">
                <option value="es">{{ $t('spanish') }}</option>
                <option value="en">{{ $t('english') }}</option>
              </select>-->
            </ul>

            <div class="nav-right">
              <a href="https://system.mikmic.com/">
                <button
                  href="https://system.mikmic.com/"
                  class="custom-button pulse-button-custom"
                >Login</button>
              </a>
            </div>
            <div class="nav-right margin-button-div">
              <a href="https://system.mikmic.com/reg/new">
                <button
                  href="https://system.mikmic.com/reg/new"
                  class="custom-button pulse-button-custom"
                >Registrate</button>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TemplateHeaderPage",
  props: {},
  data() {
    return {};
  },
  computed: {
    locale: {
      get: function () {
        return this.$i18n.locale;
      },
      set: function (newValue) {
        this.$i18n.locale = newValue;
      }
    }
  },
  watch: {
    locale: function (val) {
      this.changeLanguaje(val);
    }
  },
  methods: {
    toggleMenu() {
      window
        .$('<div class="mask-overlay">')
        .hide()
        .appendTo("body")
        .fadeIn("fast"),
        window
          .$("html")
          .addClass("no-scroll sidebar-open")
          .height(window.innerHeight + "px"),
        window.$("#wpadminbar").length
          ? window.$(".sidebar-open .site-nav").css("top", "46px")
          : window.$(".sidebar-open .site-nav").css("top", "0");
    },
    closeMenu() {
      window.$("html").removeClass("no-scroll sidebar-open").height("auto");
      window.$(".mask-overlay").remove();
    },
    changeLanguaje(lang) {
      this.$i18n.locale = lang;
      this.$setDefaultHTTPLanguajeCode();
    }
  }
};
</script>
